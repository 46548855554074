<template>
  <div>
    <h4 v-if="isNaN(profile_cycle.id) && show_title">
      Crear {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}
    </h4>
    <h4 v-else-if="show_title">
      Editar {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}
    </h4>
    <div>
      <b-form-group
        label="Nombre"
        label-for="name-input"
        label-cols="0"
        label-cols-sm="3"
        class="p-0 m-0 mb-2"
      >
        <b-form-input
          id="name-input"
          name="name-input"
          v-model="$v.profile_cycle.name.$model"
          :state="validateState('name')"
          aria-describedby="input-name-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-name-live-feedback"
          >Este campo es obligatorio y debe tener al menos 3
          caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Descripción"
        label-for="description-input"
        label-cols="0"
        label-cols-sm="3"
        class="mb-2"
      >
        <b-form-textarea
          id="description-input"
          name="description-input"
          v-model="$v.profile_cycle.description.$model"
          :state="validateState('description')"
          aria-describedby="input-description-live-feedback"
        ></b-form-textarea>

        <b-form-invalid-feedback id="input-description-live-feedback"
          >Este campo es obligatorio y debe tener al menos 3
          caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Nivel de Inicio"
        label-for="init-semester-input"
        label-cols="0"
        label-cols-sm="3"
        class="mb-2"
      >
        <b-form-input
          id="init-semester-input"
          name="init-semester-input"
          v-model="$v.profile_cycle.init_semester.$model"
          @change="forceRerender"
          type="number"
          :state="validateState('init_semester')"
          aria-describedby="input-init-semester-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-init-semester-live-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Nivel de Término"
        label-for="end-semester-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-input
          id="end-semester-input"
          name="end-semester-input"
          v-model="$v.profile_cycle.end_semester.$model"
          type="number"
          @change="forceRerender"
          :state="validateState('end_semester')"
          aria-describedby="input-end-semester-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-end-semester-live-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Certificación"
        label-for="certification-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-textarea
          id="certification-input"
          name="certification-input"
          v-model="$v.profile_cycle.certification.$model"
          :state="validateState('certification')"
          aria-describedby="input-certification-live-feedback"
        ></b-form-textarea>

        <b-form-invalid-feedback id="input-certification-live-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
    </div>
    <div class="row">
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength, numeric } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";

export default {
  name: "EgressProfileCycleForm",
  components: {
    // EgressProfileCycle: () =>
    //   import("@/components/reusable/EgressProfileCycle"),
  },
  mixins: [validationMixin],
  props: {
    egress_profile: {
      type: Object,
    },
    modal_id: {
      type: String,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: true,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
    egress_profile_cycles: {
      type: Array,
    },
  },
  data() {
    return {
      componentKey: 0,
      profile_cycle: this.Cycle
        ? {
            id: this.Cycle.id,
            name: this.Cycle.name,
            description: this.Cycle.description,
            init_semester: this.Cycle.init_semester,
            end_semester: this.Cycle.end_semester,
            certification: this.Cycle.certification,
          }
        : {
            id: generateUniqueId(),
            name: "",
            description: "",
            init_semester: 1,
            end_semester: 1,
            certification: "",
          },
    };
  },
  validations: {
    profile_cycle: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {},
      init_semester: {
        numeric,
      },
      end_semester: {
        numeric,
      },
      certification: {},
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.profile_cycle[key];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.profile_cycle.$touch();
      if (this.$v.profile_cycle.$anyError) {
        return;
      }
      if (isNaN(this.profile_cycle.id)) this.createProfileCycle();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    createProfileCycle() {
      this.$store
        .dispatch(names.POST_CYCLE, this.profile_cycle)
        .then((response) => {
          this.profile_cycle.id = response.id;
          let payload = {
            egress_profile: this.egress_profile.id,
            init_semester: this.profile_cycle.init_semester,
            end_semester: this.profile_cycle.end_semester,
            cycle: this.profile_cycle.id,
            selected: true,
          };
          this.$store
            .dispatch(names.POST_PROFILE_CYCLE, payload)
            .then((response2) => {
              if (response2) {
                toast(
                  this.$getVisibleNames(
                    "mesh.cycle",
                    false,
                    "Ciclo Formativo"
                  ) + " creado en el Perfil."
                );
                this.$emit("created", response2);
                this.$emit("close", this.modal_id);
              }
            });
        });
    },
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  created() {},
  mounted() {},
};
</script>

<style scoped>
.textarea {
  border: 1px solid rgb(160, 160, 160);
}
</style>